<template>
  <div class="block mt-2">
    <div class="columns is-centered">
      <div class="column">
        <b-field label="Month">
            <b-select @input="updateLoginMonth"
            placeholder="Select a month">
                <option
                    v-for="(option, i) in months"
                    :value="i"
                    :key="i">
                    {{ option }}
                </option>
            </b-select>
        </b-field>
      </div>
    </div>
    <div class="columns has-text-left is-centered">
      <div class="column is-3">
        <h1 class="title mt-1">Date Wise</h1>
        <table class="table">
          <tbody>
            <tr v-for="(day, i) in processedData" :key="i">
              <td>{{i+1}}
                {{new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                .toLocaleString('default', { month: 'long' })}}</td>
              <td>{{formatMinutes(day)}}</td>
            </tr>
          </tbody>

        </table>
      </div>
      <div class="column is-8">
        <h1 class="title">Sessions</h1>

<b-table
            :data="logins"
            ref="table"
            paginated
            per-page="15"
            detail-key="createdAt"
            detail-transition="fade"
            :opened-detailed="defaultOpenedDetails"
            :show-detail-icon="showDetailIcon"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column field="createdAt" label="Created At" sortable v-slot="props">
                <template>
                    <a>
                        {{ new Date(props.row.createdAt).toLocaleString() }}
                    </a>
                    <a>
                    <b-tag :type="props.row.alive ? 'is-success' : 'is-danger'">
                      Duration: {{getDuration(props.row, new Date())}}</b-tag>
                    </a>
                </template>
            </b-table-column>

            <b-table-column field="completedAt" label="Completed At" sortable v-slot="props">
                {{ new Date(props.row.completedAt).toLocaleString() }}
            </b-table-column>
        </b-table>

        <!-- <div class="box" v-for="login in logins" :key="login.id">
          <div class="columns">
            <div class="column">
              <b>Login</b>
              <br>
              Started At:
              {{ new Date(login.createdAt).toString().split(" GMT")[0] }}
              <br />
              Completed At:
              {{
                new Date(login.completedAt || "").toString().split(" GMT")[0]
              }}
              <br />
              Duration {{ getDuration(login) }}
            </div>
            <div v-if="login.Breaks.length" class="column">
                <b>Break</b>
              <div class="box" v-for="tbreak in login.Breaks" :key="tbreak.id">
              <br>
                Started At:
                {{ new Date(tbreak.createdAt).toString().split(" GMT")[0] }}
                <br />
                Completed At:
                {{
                  new Date(tbreak.completedAt || "").toString().split(" GMT")[0]
                }}
                <br />
                Duration {{ getDuration(tbreak) }}
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AgentBreak',
  data: () => ({
    dataCollection: null,
    showDetailIcon: true,
    defaultOpenedDetails: [1],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  }),
  methods: {
    ...mapActions(['setAgentLogins', 'setAgentLoginMonth']),
    async updateLoginMonth(month) {
      this.setAgentLoginMonth(month);
      await this.setAgentLogins(this.$route.params.agentId);
    },
    getDuration(login) {
      let elapsedMSeconds;
      if (login.completedAt) {
        elapsedMSeconds = new Date(login.completedAt) - new Date(login.createdAt);
      } else {
        elapsedMSeconds = 0;
      }
      const elapsedSecs = elapsedMSeconds / 1000;
      return this.formatMinutes(elapsedSecs);
    },
    formatMinutes(seconds) {
      const elapsedMins = (seconds / 60).toFixed(0);
      const elapsedHours = Math.floor(elapsedMins / 60);
      const modMins = (elapsedMins % 60).toFixed(0);
      return `${elapsedHours < 10 ? `0${elapsedHours}` : elapsedHours}:${
        modMins < 10 ? `0${modMins}` : modMins
      }`;
    },
  },
  computed: {
    ...mapState({
      logins: ({ agentData: { logins } }) => logins,
      processedData: ({ agentData: { processedData } }) => processedData,
    }),
  },
  async mounted() {
    await this.setAgentLogins(this.$route.params.agentId);
  },
};
</script>

<style scoped>
.level > .level-item.box {
  margin: 1em;
}

.block-custom {
  margin: 1.9em;
}
</style>
